import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import path from "path"
import "../styles/style.css"

const QuestionsPage = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const files = data.allMarkdownRemark.edges.map(s => {
    const filename = path.basename(s.node.fileAbsolutePath)
    const link = filename.replace(/\.[^/.]+$/, "")
    return {
      number: s.node.frontmatter.number,
      title: s.node.frontmatter.title,
      link,
    }
  })

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Azure AZ-104 questions</h1>

      {data &&
        files.map((s, i) => {
          const title = s.title ? `${s.number}# ${s.title}` : s.number

          return (
            <Link to={"/".concat(s.link)} key={i} className="question">
              {title}
            </Link>
          )
        })}

      <br />
    </Layout>
  )
}

export default QuestionsPage

export const pageQuery = graphql`
  query MyQueryQuestions {
    allMarkdownRemark(
      filter: { frontmatter: { number: { gte: 0 } } }
      sort: { fields: frontmatter___number }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            number
            title
          }
        }
      }
      totalCount
    }
  }
`
